/** @jsx jsx */
import { jsx, Box, Button, Container, Grid, Flex, Styled} from 'theme-ui'
import React from "react"
import {graphql, Link} from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'



  
const IndexPage = ({data}) => {

  const posts = data.posts.edges
  const pug = data.pug


return (
  <section>
    <SEO title="Dog Community" description="Dog encyclopedia and community. Know your breed before your buy or adopt." />

 
    {/* <Img fluid={rex.childImageSharp.fluid} sx={{width: "100%", maxWidth: "800px"}} /> */}
    <Grid columns={[1,1,2]} sx={{gridAutoColumns: "1fr", minHeight: 'calc(100vh - 150px)'}}>
      <Flex sx={{justifyContent: 'flex-end', alignItems: 'center'}}>
        <Box sx={{width: 'small', mx: 5}}>
          <Styled.h1 sx={{textAlign: ['center','center','left'], mt: [5,5,0]}}>Barkpedia</Styled.h1>
          <Styled.h2>Know Your Breed Before You Buy (or Adopt).</Styled.h2>
          <Styled.h4 sx={{mb: 5}}>The most accurate dog breed information sourced from real dog owners.</Styled.h4>
          <Button as={Link} to="/breeds">Find Your Breed</Button>
        </Box>
      </Flex>
      <Flex sx={{height: "100%", justifyContent: "flex-end", alignItems: "flex-end"}}>
        <Img fluid={pug.childImageSharp.fluid} sx={{width: "100%", maxWidth: "600px"}} />
      </Flex>
    </Grid>

    <Box sx={{py: 5}}>
      <Container>
        {/* Posts */}
        <Box sx={{m: 5}}>
          <Styled.h6 sx={{fontWeight: 'bold'}}>📝 Recent Posts</Styled.h6>
          {posts.map(({node,i}) => (
            <Flex key={i} sx={{alignItems: 'center', boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: '5px', mt: 3}}>
                <BackgroundImage
                fluid={node.mainImage.asset.fluid}
                sx={{width: '100%', minWidth: '150px', maxWidth: '150px', minHeight: '150px', height: '100%'}}
                backgroundColor={`#000`}
              />
              <Box sx={{px: 5, py: 4}}>
                <Styled.h5 sx={{my: '0'}}>{node.title}</Styled.h5>
                <Styled.a as={Link} to={`/posts/${node.slug.current}`}>Read More</Styled.a>
              </Box>
            </Flex>
          ))}
        </Box>
      </Container>
    </Box>


  </section>
)


}

export default IndexPage

export const query = graphql`
  query {

    pug: file(relativePath: { eq: "pug.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    chris: file(relativePath: { eq: "christmaspups.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   
   
    posts: allSanityPost(filter: {featured: {eq: true}}) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }

  }
`